import {FetchQueryOptions, QueryClient} from "@tanstack/vue-query";
import {unref} from "vue";
import {MaybeRef} from "@tanstack/vue-query/build/legacy/types";
import {AxiosError} from "axios";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount: number, error: Error) => {
        if (failureCount > 0)
          return false;

        if (error instanceof AxiosError && error.response)
          if (error.response.status >= 400 && error.response.status < 500)
            return false;

        return true;
      },
      staleTime: 60 * 60 * 1000,
      refetchOnWindowFocus: false,
    },
  },
});

type AsyncFunction = ((...args: any[]) => Promise<any>);
type MaybeRefALl<T> = {
  [K in keyof T]: MaybeRef<T[K]>;
};

export function getQuery<T extends AsyncFunction>(
    fn: T,
    ...args: MaybeRefALl<Parameters<T>>
): FetchQueryOptions<Awaited<ReturnType<T>>> {
  return {
    queryKey: [fn.name, ...args],
    queryFn: async (): Promise<Awaited<ReturnType<T>>> => {
      const unwrappedArgs = args.map((arg: MaybeRef<unknown>) => unref(arg));
      return await fn(...unwrappedArgs) as Awaited<ReturnType<T>>;
    },
  };
}
