import keycloak from "@/plugins/keycloak";
import {RouteConfig} from "vue-router/types/router";

export const SUBTITLE_UTILITY = "Utility";
export const SUBTITLE_CUSTOMER = "Customer";

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "index",
    redirect: {name: "customer-dashboard"},
  },
  // DASHBOARD PAGES
  {
    path: "/customer-dashboard",
    name: "customer-dashboard",
    component: () => import(/* webpackChunkName: "customer-dashboard" */ "@/views/dashboard/customer/CustomerDashboardView.vue"),
    meta: {
      subtitle: SUBTITLE_CUSTOMER,
    },
  },
  {
    path: "/customer-performance",
    name: "customer-performance-dashboard",
    component: () => import(/* webpackChunkName: "customer-dashboard" */ "@/views/dashboard/customer/CustomerPerformanceView.vue"),
    meta: {
      hasAuth: (authStore, flmStore) => flmStore.site?.utility !== "BED",
      subtitle: SUBTITLE_CUSTOMER,
    },
  },
  {
    path: "/telemetry",
    name: "telemetry",
    component: () => import(/* webpackChunkName: "telemetry" */ "@/views/dashboard/customer/TelemetryView.vue"),
    meta: {
      hasAuth: (authStore, flmStore) => flmStore.site?.isTelemetryDashboardEnabled,
      subtitle: SUBTITLE_CUSTOMER,
    },
  },
  {
    path: "/support",
    name: "support",
    component: () => import(/* webpackChunkName: "support-view" */ "@/views/dashboard/customer/SupportView.vue"),
    meta: {
      subtitle: SUBTITLE_CUSTOMER,
    },
  },
  {
    path: "/logbook",
    name: "logbook",
    component: () => import(/* webpackChunkName: "logbook" */ "@/views/dashboard/customer/LogbookView.vue"),
    meta: {
      hasAuth: (authStore, flmStore) => flmStore.siteRoles?.canViewLogbookEntries,
      subtitle: SUBTITLE_CUSTOMER,
    },
  },
  {
    path: "/logbook-aggregate",
    name: "logbook-aggregate",
    component: () => import(/* webpackChunkName: "logbook-aggregate" */ "@/views/dashboard/utility/LogbookAggregateView.vue"),
    meta: {
      hasAuth: (authStore, flmStore) => flmStore.hasAnyUtility,
      subtitle: SUBTITLE_UTILITY,
    },
  },
  {
    path: '/rate-explanation',
    name: 'rate-explanation',
    component: () => import(/* webpackChunkName: "customer-rate-explanation" */ "@/views/dashboard/customer/RateExplainerView.vue"),
    meta: {
      subtitle: SUBTITLE_CUSTOMER,
      hasAuth: (authStore, flmStore) => flmStore.site?.flmCohort === "FLM3_FLAT" || flmStore.site?.flmCohort === "FLM3_TOU",
    },
  },
  {
    path: "/site-settings",
    name: "site-settings",
    component: () => import(/* webpackChunkName: "site-settings" */ "@/views/dashboard/customer/SiteSettingsView.vue"),
    meta: {
      hasAuth: (authStore, flmStore) => {
        return flmStore.siteRoles?.canManageSettings
            || flmStore.siteRoles?.canManageUsers
            || authStore.roles.accessAllSites;
      },
      subtitle: SUBTITLE_CUSTOMER,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import(/* webpackChunkName: "user-settings" */ "@/views/dashboard/UserSettingsView.vue"),
  },
  {
    path: "/tracking-settlement",
    name: "tracking-settlement",
    component: () => import(/* webpackChunkName: "tracking-settlement" */ "@/views/dashboard/utility/TrackingSettlementView.vue"),
    meta: {
      hasAuth: (authStore, flmStore) => flmStore.hasAnyUtility,
      subtitle: SUBTITLE_UTILITY,
    },
  },
  {
    path: "/flm3-tracking",
    name: "flm3-tracking",
    component: () => import(/* webpackChunkName: "flm3-tracking" */ "@/views/dashboard/utility/FLM3TrackingView.vue"),
    meta: {
      hasAuth: (authStore, flmStore) => !!flmStore.utilities.GMP,
      subtitle: SUBTITLE_UTILITY,
    },
  },
  {
    path: "/utility-dashboard",
    name: "utility-dashboard",
    component: () => import(/* webpackChunkName: "tracking-settlement" */ "@/views/dashboard/utility/UtilityDashboardView.vue"),
    meta: {
      hasAuth: (authStore, flmStore) => flmStore.utility?.roles?.canViewUtilityDashboard,
      subtitle: SUBTITLE_UTILITY,
    },
  },
  {
    path: "/bill-management",
    name: "bill-management",
    component: () => import(/* webpackChunkName: "bill-management" */ "@/views/dashboard/BillManagementView.vue"),
    meta: {
      hasAuth: (authStore, flmStore) => {
        return flmStore.hasAnyUtility && flmStore.utilityId !== 'BED' && flmStore.utilityRoles?.canManageBills;
      },
      subtitle: SUBTITLE_UTILITY,
    },
  },
  {
    path: "/meter-model-analysis",
    name: "meter-model-analysis",
    component: () => import(/* webpackChunkName: "model-analysis" */ "@/views/dashboard/internal/ModelAnalysisView.vue"),
    meta: {
      hasAuth: (authStore) => authStore.roles.viewPageModelAnalysis,
    },
  },
  {
    path: "/system-model-analysis",
    name: "system-model-analysis",
    component: () => import(/* webpackChunkName: "model-analysis" */ "@/views/dashboard/internal/SystemAnalysisView.vue"),
    meta: {
      hasAuth: (authStore) => authStore.roles.viewPageModelAnalysis,
    },
  },

  // AUTH
  {
    path: "/auth/profile",
    name: "profile",
    meta: {
      getExternal: () => {
        return {href: keycloak.createAccountUrl(), target: "_blank"};
      },
    },
  },
  {
    path: "/auth/logout",
    name: "logout",
    meta: {
      isPublic: true,
      getExternal: () => {
        return {href: keycloak.createLogoutUrl(), target: "_blank"};
      },
    },
  },
  // 403
  {
    path: "/auth/forbidden",
    name: "403",
    component: () => import(/* webpackChunkName: "error" */ "@/views/error/ForbiddenView.vue"),
    meta: {
      layout: "ErrorLayout",
      isPublic: true,
    },
  },
  {
    path: "/auth/no-sites",
    name: "403-no-sites",
    component: () => import(/* webpackChunkName: "error" */ "@/views/error/NoSites.vue"),
    meta: {
      layout: "ErrorLayout",
      isPublic: true,
    },
  },
  // 404
  {
    path: "*",
    name: "error",
    component: () => import(/* webpackChunkName: "error" */ "@/views/error/NotFoundView.vue"),
    meta: {
      layout: "ErrorLayout",
    },
  },
  {
    path: "/error/offline",
    name: "offline",
    component: () => import(/* webpackChunkName: "error" */ "@/views/error/OfflineView.vue"),
    meta: {
      layout: "ErrorLayout",
      isPublic: true,
    },
  },
];

export default routes;
