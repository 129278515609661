import {Account, Meter, SubmeterField} from "@/stores/flm/types";
import {DateTime, Interval} from "luxon";
import {formatNumber} from "@/util/format";
import {useFLMStore} from "@/stores/flm";
import {getQuery, queryClient} from "@/api/queryClient";
import {client} from "@/api/client";
import {DataType, PlotStyle, TelemetryPoint, TelemetryReading, ValuePeriod} from "@/components/telemetry/types";
import {DataGroupingApproximationValue} from "highcharts";
import {getEventPerformanceQueryKey} from "@/composables/queries/useEventPerformanceQuery";

export abstract class TelemetryItem {
  readonly SOURCE_TYPE: 'periods' | 'rows' = 'rows';

  protected _dataType: DataType = DataType.NUMBER;
  protected _unit: string | null = null;
  protected _dataGroupingApproximation: DataGroupingApproximationValue | null = 'average';

  plotStyle: PlotStyle;
  order: number;

  protected constructor(plotStyle?: PlotStyle, order?: number) {
    this.plotStyle = plotStyle ?? 'step';
    this.order = order ?? 0;
  }

  get dataType(): DataType {
    return this._dataType;
  }

  abstract get name(): string;

  get unit(): string | null {
    return this._unit;
  }

  // put all of same unit on same axis id
  // if no unit, 2 shared axes for booleans and enums
  // otherwise, default to axis per item
  get yAxisId(): string {
    if (this.unit)
      return this.unit;
    if (this.dataType === DataType.BOOLEAN)
      return 'boolean';
    if (this.dataType === DataType.ENUM)
      return 'enum';
    return this.name;
  }

  get isEnumLike(): boolean {
    return [DataType.ENUM, DataType.BOOLEAN].includes(this.dataType);
  }

  get dataGroupingApproximation(): DataGroupingApproximationValue | null {
    return this._dataGroupingApproximation;
  }

  abstract clone(order?: number): TelemetryItem;

  formatValue(value: number | undefined | null): string {
    if (value === undefined)
      return "Unknown";
    if (value === null)
      return "N/A";
    return formatNumber(value);
  }

  abstract fetchData(range: Interval): Promise<any>;
}

export class TelemetryPointItem extends TelemetryItem {
  point: TelemetryPoint;

  constructor(point: TelemetryPoint, plotStyle?: PlotStyle, order?: number) {
    super(plotStyle, order);
    this.point = point;
    if (!plotStyle && this.isEnumLike)
      this.plotStyle = 'regions';
  }

  get name(): string {
    return this.point.displayName ?? this.point.name ?? "unknown";
  }

  get unit(): string | null {
    return this.point.unit;
  }

  get dataType(): DataType {
    return this.point.dataType;
  }

  clone(order?: number): TelemetryPointItem {
    return new TelemetryPointItem(this.point, this.plotStyle, order ?? this.order);
  }

  formatValue(value: number): string {
    if (this.dataType === DataType.BOOLEAN) {
      const valuesText = this.point.meta.valuesText ?? {"0": false, "1": true};
      const text = valuesText[String(value)];
      if (text !== undefined)
        return text;
    }
    return super.formatValue(value);
  }

  async fetchData(range: Interval): Promise<TelemetryReading[]> {
    const {siteId} = useFLMStore();
    return await queryClient.fetchQuery({
      queryKey: ['telemetryReading', this.point.id, range],
      queryFn: async () => {
        return await client.telemetry.getTelemetryReadings(siteId!, this.point.id, range.start, range.end);
      },
    });
  }
}

export class TelemetryAccountItem extends TelemetryItem {
  account: Account;
  protected _unit = 'kW';

  constructor(account: Account, plotStyle?: PlotStyle, order?: number) {
    super(plotStyle, order);
    this.account = account;
  }

  get name(): string {
    if (this.account.label)
      return `Demand: Account #${this.account.id} (${this.account.label})`;
    return `Demand: Account #${this.account.id}`;
  }

  clone(order?: number): TelemetryAccountItem {
    return new TelemetryAccountItem(this.account, this.plotStyle, order ?? this.order);
  }

  async fetchData(range: Interval): Promise<TelemetryReading[]> {
    const {siteId, site} = useFLMStore();

    const fullAccount = site!.accounts.find((a) => a.id === this.account.id)!;
    const freq = Math.max(...fullAccount.meters.map((m) => m.freq));

    const data = await queryClient.fetchQuery(getQuery(
        client.site.getAccountUsage,
        siteId!,
        this.account.id,
        range.start,
        range.end,
        freq === 15 ? '15T' : '1H',
    ));
    return data.rows.map((row) => ({
      dt: row.dt,
      value: freq === 15 && row.usage !== null ? 4 * row.usage : row.usage,
    }));
  }
}

export class TelemetryMeterItem extends TelemetryItem {
  meter: Meter;
  protected _unit = 'kW';

  constructor(meter: Meter, plotStyle?: PlotStyle, order?: number) {
    super(plotStyle, order);
    this.meter = meter;
  }

  get name(): string {
    if (this.meter.label)
      return `Demand: Meter #${this.meter.id} (${this.meter.label})`;
    return `Demand: Meter #${this.meter.id}`;
  }

  clone(order?: number): TelemetryMeterItem {
    return new TelemetryMeterItem(this.meter, this.plotStyle, order ?? this.order);
  }

  async fetchData(range: Interval): Promise<any> {
    const {siteId} = useFLMStore();
    const data = await queryClient.fetchQuery(getQuery(
        client.site.getMeterUsage,
        siteId!,
        this.meter.id,
        range.start,
        range.end,
        this.meter.freq === 15 ? '15T' : '1H',
    ));
    return data.rows.map((row) => ({
      dt: row.dt,
      value: this.meter.freq === 15 && row.usage !== null ? 4 * row.usage : row.usage,
    }));
  }
}

export class TelemetrySubmeterFieldItem extends TelemetryItem {
  submeterField: SubmeterField;
  protected _unit = 'kW';

  constructor(submeterField: SubmeterField, plotStyle?: PlotStyle, order?: number) {
    super(plotStyle, order);
    this.submeterField = submeterField;
  }

  get name(): string {
    const name = this.submeterField.displayName ?? this.submeterField.sourceName;
    return `Submeter: ${name}`;
  }

  clone(order?: number): TelemetrySubmeterFieldItem {
    return new TelemetrySubmeterFieldItem(this.submeterField, this.plotStyle, order ?? this.order);
  }

  async fetchData(range: Interval): Promise<TelemetryReading[]> {
    const {siteId} = useFLMStore();
    const data = await queryClient.fetchQuery({
      queryKey: ['submeterReadings', this.submeterField.id, range.start, range.end],
      queryFn: async () => {
        return client.site.getSubmeterReadings(siteId!, this.submeterField.id!, range.start, range.end);
      },
    });
    // freq is always 15 minutes, convert kWh to kW
    return data.rows.map((row) => ({
      dt: row.dt,
      value: row.usage !== null ? 4 * row.usage : row.usage,
    }));
  }
}

export class TelemetryEventItem extends TelemetryItem {
  readonly SOURCE_TYPE = 'periods';
  protected _dataType: DataType = DataType.BOOLEAN;
  protected _dataGroupingApproximation: DataGroupingApproximationValue | null = null;

  constructor() {
    super('regions', -2);
  }

  get name(): string {
    return "FLM Event";
  }

  clone(): TelemetryEventItem {
    return new TelemetryEventItem();
  }

  formatValue(value: number): string {
    return value ? "Active" : "Inactive";
  }

  async fetchData(range: Interval): Promise<ValuePeriod<DateTime>[]> {
    // TODO: would be more efficient to just have an endpoint that returns the intervals
    const {siteId} = useFLMStore();

    const data = await queryClient.fetchQuery(getEventPerformanceQueryKey(siteId));
    return data.raw.map((event) => {
      return event.intervals.filter((interval) => {
        return range.overlaps(interval);
      }).map((interval) => ({
        start: interval.start,
        end: interval.end,
        value: 1,
      }));
    }).flat();
  }
}

export class TelemetrySignalItem extends TelemetryItem {
  protected _dataGroupingApproximation: DataGroupingApproximationValue | null = null;
  constructor(plotStyle?: PlotStyle) {
    super(plotStyle, -1);
  }

  get name(): string {
    return "FLM Signal";
  }

  clone(): TelemetrySignalItem {
    return new TelemetrySignalItem(this.plotStyle);
  }

  async fetchData(range: Interval): Promise<TelemetryReading[]> {
    const {siteId} = useFLMStore();
    const data = await queryClient.fetchQuery({
      queryKey: ['flmSignal', siteId!, range.start, range.end],
      queryFn: async () => {
        return client.site.getFLMSignals(siteId!, range.start, range.end);
      },
    });

    return data.rows.map((row) => ({
      dt: row.dt,
      value: row.signal,
    }));
  }
}

type WeatherField = { value: string, name: string, unit: string };

export class TelemetryWeatherItem extends TelemetryItem {
  static readonly FIELDS: WeatherField[] = [
    {value: 'temperature', name: 'Temperature', unit: '°F'},
    {value: 'apparentTemperature', name: 'Apparent Temperature', unit: '°F'},
    {value: 'heatIndex', name: 'Heat Index', unit: '°F'},
    {value: 'windChillTemperature', name: 'Wind Chill', unit: '°F'},
    {value: 'dewPointTemperature', name: 'Dew Point Temperature', unit: '°F'},
    {value: 'humidity', name: 'Humidity', unit: '%'},
    {value: 'cloudCover', name: 'Cloud Cover', unit: '%'},
    {value: 'windSpeed', name: 'Wind Speed', unit: 'mph'},
    {value: 'windGust', name: 'Wind Gust', unit: 'mph'},
    {value: 'precipitation', name: 'Precipitation', unit: 'in'},
    {value: 'snowfall', name: 'Snowfall', unit: 'in'},
    {value: 'globalHorizontalIrradiance', name: 'Global Horizontal Irradiance', unit: 'W/sqm'},
    {value: 'directNormalIrradiance', name: 'Direct Normal Irradiance', unit: 'W/sqm'},
  ];

  field: WeatherField;
  protected _dataType: DataType = DataType.NUMBER;

  constructor(field: WeatherField, plotStyle?: PlotStyle, order?: number) {
    super(plotStyle, order);
    this.field = field;
  }

  get name(): string {
    return `Weather (${this.field.name})`;
  }

  get unit(): string {
    return this.field.unit;
  }

  clone(order?: number): TelemetryItem {
    return new TelemetryWeatherItem(this.field, this.plotStyle, order ?? this.order);
  }

  async fetchData(range: Interval): Promise<TelemetryReading[]> {
    const {site} = useFLMStore();
    const data = await queryClient.fetchQuery({
      queryKey: ['weatherObservations', site!.weatherStationId, range.start, range.end],
      queryFn: async () => {
        return await client.site.getWeatherObservations(site!.weatherStationId, range.start, range.end);
      },
    });
    return data.rows.map((row) => {
      let value = row[this.field.value];
      // backend saves between 0 & 1, chart expects between 0 & 100 for %
      if (this.field.unit === '%')
        value = value * 100;
      return {
        dt: row.dt,
        value,
      };
    });
  }
}

