import {DateTime} from "luxon";
import {TelemetryItem} from "@/components/telemetry/item";

export type Row = [number, number | undefined | null];

export type PlotStyle = 'step' | 'line' | 'spline' | 'column' | 'regions' | 'xrange';
export type ValuePeriod<TIndex = number> = { start: TIndex, end: TIndex, value: number | undefined | null };

export enum DataType {
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  ENUM = 'ENUM'
}

export interface TelemetryPointMeta {
  dataType?: DataType;
  valuesText?: Record<string, string>;
  [key: string]: any;
}

export interface TelemetryPoint {
  id: string;
  siteId: number;
  isIncludedDashboard: boolean;
  path: string;
  name: string | null;
  displayName: string | null;
  unit: string | null;
  freq: number | null;
  dataType: DataType;
  interpolationMethod: 'LOCF' | 'LINEAR';
  meta: TelemetryPointMeta;
}

export interface TelemetryReading {
  dt: DateTime;
  value?: number | null;
}

export interface TelemetryGroupOptions {
  name: string;
  usePanes: boolean;
  showNavigator: boolean;
  height: number | null;
  freq: number;
}

export interface TelemetryGroup extends TelemetryGroupOptions {
  id?: number;
  items: Array<TelemetryItem>;
}

export interface SavedTelemetryGroup extends TelemetryGroup {
  id: number;
}

export interface TelemetryLayoutItem {
  order: number | null;
  group: SavedTelemetryGroup;
}

export interface SavedTelemetryLayout {
  id: number;
  name: string;
  items: TelemetryLayoutItem[];
}
